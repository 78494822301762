<template>
  <div class="document-request-from">
    <Header :typeComponent="'form'"/>
    <v-col>
      <v-row>
        <BaseContentIntro>
          <template v-slot:title>
            <span class="color-title">{{ $t('documentRequest.client.settingsTitle') }}</span>
          </template>
        </BaseContentIntro>
      </v-row>
    </v-col>
    <RequestForMember v-if="typeStep === constant.STEP_BY_STEP.STEP_1"
                      @eventChangeRequestForMember="handlerClickReqForMember"/>
    <!-- <FromIntroDocRequest/> --><!-- removed, see uat inquiry-18 -->
    <BreadcrumbStep :dataItems="dataBreadcrumbs" @eventChangeStep="handlerClickStep"/>

    <template v-if="typeStep === constant.STEP_BY_STEP.STEP_1">
      <p class="step1"></p>
      <MainFormDocRequest @eventChangeDataDocReq="handlerMainFormDocRequest"
                          :dataModel="dataMainFormDocRequest"/>

      <FormDivider/>
      <FacilityInterested :titleFacilityInterested="'いずれかをお選びください。（複数選択可）'"
                          @eventChangeFacilityInterested="handlerFacilityInterested"
                          :dataModel1="dataFacilityInterestedOptional1"
                          :dataModel2="dataFacilityInterestedOptional2"/>
      <FormDivider/>

      <FindAboutVacations :titleFindAboutVacation="'以下のアンケートにお答えください。'"
                          @eventChangeFindAboutVacation="handlerFindAboutVacation"
                          :dataModel="dataFindAboutVacation"/>

      <PolicyAgree @eventPolicyAgree="handlerPolicyAgree"
                   :dataModel="dataPolicyAgree"/>

      <IfEmailNotReceived />

      <v-col class="d-flex justify-center align-center d-box-act mb-9">
        <v-btn class="btn-action-from pa-0 pl-2" color="primary" :disabled="valid" @click="funcConfirmStep1Form">
          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
          {{ $t('buttons.confirmAndSend') }}
        </v-btn>
      </v-col>
    </template>

    <template v-if="typeStep === constant.STEP_BY_STEP.STEP_2">
      <p class="step2"></p>
      <ShowDataStep2 :type="typeApply"/>

      <v-row class="d-flex justify-center align-center d-box-act mt-4 mb-9">
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="btn-action-from pa-0 pl-2" text @click="funcBackStep1Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-left</v-icon>
            {{ $t('buttons.back') }}
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="ml-2 btn-action-from pa-0 pl-2" color="primary" @click.prevent="funcSubmitStep2Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
            {{ $t('buttons.saveAndCreate') }}
            <v-progress-circular v-if="noClickStep2" indeterminate color="primary" :size="18" :width="2"/>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="typeStep === constant.STEP_BY_STEP.STEP_3">
      <ShowDataStep3 />
<!--      <v-col class="d-flex justify-center align-center d-box-act">-->
<!--        <v-btn class="btn-action-from" text @click="funcAfterSubmitStep3Form">-->
<!--          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>-->
<!--          TODO: After Submit success ....-->
<!--        </v-btn>-->
<!--      </v-col>-->
    </template>

    <Footer :typeComponent="'form'"/>
  </div>
</template>

<script>
import BaseContentIntro from '@/components/common/BaseContentIntro'
import BreadcrumbStep from '@/components/common/BreadcrumbStep'
import PolicyAgree from '@/components/common/PolicyAgree'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import RequestForMember from '@/components/documentRequest/client/RequestForMember'
import ShowDataStep2 from '@/components/documentRequest/client/ShowDataStep2'
import FindAboutVacations from '@/components/common/FindAboutVacations'
import FacilityInterested from '@/components/common/FacilityInterested'
import MainFormDocRequest from '@/components/common/MainFormDocRequest'
import ShowDataStep3 from '@/components/common/ShowDataStep3'
import FormDivider from '@/components/common/FormDivider'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'
import IfEmailNotReceived from '@/components/common/IfEmailNotReceived'

export default {
  mixins: [
    createWebRequest
  ],
  data () {
    return {
      noClickStep2: false,
      typeValidForMember: 'REQ_CHECKBOX_RFM',
      typeValidFindAboutVacation: 'REQ_CHECKBOX_FAV',
      typeValidMainFormDocRequest: 'REQ_CHECKBOX_MFDR',
      typeValidPolicyAgree: 'REQ_CHECKBOX_PA'
    }
  },
  components: {
    ShowDataStep3,
    ShowDataStep2,
    FormDivider,
    MainFormDocRequest,
    FacilityInterested,
    FindAboutVacations,
    RequestForMember,
    BreadcrumbStep,
    BaseContentIntro,
    PolicyAgree,
    Footer,
    Header,
    IfEmailNotReceived
  },
  mounted () {
    this.$store.dispatch('stepClientDocRequestTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
    this.typeValidPolicyAgree = this.dataClientDocRequest.policyAgree ? '' : this.typeValidPolicyAgree
    this.typeValidForMember = this.dataClientDocRequest.requestForMember?.value ? '' : this.typeValidForMember
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      typeStep: state => state.documentRequest.stepClientDocRequestType
    }),
    dataPolicyAgree () {
      // Get data for v-model
      return this.dataClientDocRequest.policyAgree
    },
    dataFindAboutVacation () {
      // Get data for v-model
      return this.dataClientDocRequest.findAboutVacation
    },
    dataFacilityInterestedOptional1 () {
      // Get data for v-model
      return this.dataClientDocRequest.facilityInterestedOption1
    },
    dataFacilityInterestedOptional2 () {
      // Get data for v-model
      return this.dataClientDocRequest.facilityInterestedOption2
    },
    dataMainFormDocRequest () {
      // Get data for v-model
      return this.dataClientDocRequest.mainFormDocRequest
    },
    dataBreadcrumbs () {
      return [{
        step: 1,
        name: this.$t('documentRequest.stepByStep.step1'),
        active: this.constant.STEP_BY_STEP.STEP_1 === this.typeStep
      }, {
        step: 2,
        name: this.$t('documentRequest.stepByStep.step2'),
        active: this.constant.STEP_BY_STEP.STEP_2 === this.typeStep
      }, {
        step: 3,
        name: this.$t('documentRequest.stepByStep.step3'),
        active: this.constant.STEP_BY_STEP.STEP_3 === this.typeStep
      }]
    },
    valid () {
      return [
        this.typeValidForMember,
        this.typeValidFindAboutVacation,
        this.typeValidPolicyAgree,
        this.typeValidMainFormDocRequest
      ].some(ele => ele.length)
    }
  },
  methods: {
    handlerClickStep (data) {
      // console.log(data)
    },
    handlerPolicyAgree (dataBoolean) {
      this.typeValidPolicyAgree = dataBoolean ? '' : 'REQ_CHECKBOX_PA'
      this.$store.dispatch('policyAgreeDispatch', { policyAgree: dataBoolean, typeApply: this.typeApply })
    },
    handlerClickReqForMember (dataObj) {
      this.typeValidForMember = Object.keys(dataObj).length > 0 ? '' : 'REQ_CHECKBOX_RFM'
      this.$store.dispatch('requestForMemberDispatch', dataObj)
    },
    handlerFindAboutVacation (dataObj) {
      this.typeValidFindAboutVacation = dataObj?.isValid && dataObj.isValid ? '' : 'REQ_CHECKBOX_FAV'
      this.$store.dispatch('findAboutVacationDispatch', { payload: dataObj.data, typeApply: this.typeApply })
    },
    handlerMainFormDocRequest (data) {
      this.typeValidMainFormDocRequest = data?.isValid && data.isValid ? '' : 'REQ_CHECKBOX_MFDR'
      this.$store.dispatch('mainFormDocClientRequestDispatch', data.data)
    },
    handlerFacilityInterested (dataObj) {
      this.$store.dispatch('mainFacilityInterestedDispatch', { payload: dataObj, typeApply: this.typeApply })
    },
    funcConfirmStep1Form () {
      this.$store.dispatch('stepClientDocRequestTypeDispatch', this.constant.STEP_BY_STEP.STEP_2)
      this.scrollIntoViewSmooth('.step2')
    },
    funcBackStep1Form () {
      this.$store.dispatch('stepClientDocRequestTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
      this.scrollIntoViewSmooth('.step1')
    },
    async funcSubmitStep2Form () {
      if (this.noClickStep2) {
        this.showMessageDbClick(this.$toast, 'w', this.$t('common.notSaveWhenDbClick'))
        return
      }
      this.noClickStep2 = true
      const payload = await this.convertDataBeforeCreateForScreenFirst()

      await this.$store.dispatch('createWebRequestDispatch', {
        apolloClient: this.$apollo,
        payload,
        typeApply: this.typeApply,
        toast: this.$toast
      }).then(res => {
        if (res) {
          this.scrollIntoViewSmooth('.step3')
          return
        }

        // eslint-disable-next-line no-return-assign
        setTimeout(() => this.noClickStep2 = false, 3200)
      })
    },
    funcAfterSubmitStep3Form () {
      // TODO
    }
  },
  created () {
    // Setup for component use data
    this.typeApply = 'CLIENT'
  }
}
</script>

<style lang="scss">

</style>
