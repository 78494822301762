<template>
  <div class="document-request-from-show-step2">
    <RequestVsVm :text="requestForMember.text || ''" />
    <MainFormRequestShow :type="type" />
    <FacilityInterestedShow :type="type" />
    <FindAboutVacationShow :type="type" />
  </div>
</template>

<script>
import C from '@/utils/constant'
import { mapState } from 'vuex'
import MainFormRequestShow from '@/components/common/ShowDataStep2/MainFormRequestShow'
import FacilityInterestedShow from '@/components/common/ShowDataStep2/FacilityInterestedShow'
import FindAboutVacationShow from '@/components/common/ShowDataStep2/FindAboutVacationShow'
import RequestVsVm from '../common/RequestVsVm.vue'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  components: {
    MainFormRequestShow,
    FacilityInterestedShow,
    FindAboutVacationShow,
    RequestVsVm
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      dataClientDocRequest: state => state.documentRequest.dataClientDocRequest
    }),
    constant () {
      return Object.assign({}, C)
    },
    requestForMember () {
      return this.dataClientDocRequest.requestForMember
    },
    mainFormDocRequest () {
      return this.dataClientDocRequest.mainFormDocRequest
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
